import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Coffee = memo(function Coffee({
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {label ? <Label>{label}</Label> : null}
        {image ? <StyledImage {...image} /> : null}
        <Inner>
          <Quotes src="/coffee_bg.png" alt="" />
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    width: calc(100% - 2.1875rem);
    margin: 5rem 0 0 2.1875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 68.125rem;
  margin: 0 auto;
  padding: 11.875rem 6.875rem 5rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  background-image: url('/coffee_bg.png');
  background-repeat: no-repeat;
  background-position: 12.3758rem 5rem;

  @media (max-width: 1600px) {
    background-position: 12.3758vw 5rem;
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 8.3125rem 2.5rem 3.4375rem;
    background-size: 10.125rem 7.3125rem;
    background-position: 15.8125vw 5rem;
  }

  @media (max-width: 992px) {
    background-image: none;
  }
`

const Label = styled.div`
  ${styles.label}
  position: absolute;
  top: 3.75rem;
  left: -6.875rem;

  @media (max-width: 1199px) {
    top: 4.375rem;
    left: -0.9375rem;
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 8.3125rem;
  left: -3.437rem;
  display: block;
  width: 18.9375rem;
  aspect-ratio: 303/342;

  @media (max-width: 1199px) {
    left: 0;
    width: 18.938vw;
    max-width: 18.9375rem;
  }

  @media (max-width: 992px) {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Inner = styled.div`
  position: relative;
  margin-left: 17.3758rem;
  z-index: 1;

  @media (max-width: 1600px) {
    margin-left: 17.3758vw;
  }

  @media (max-width: 1199px) {
    margin-left: 20.8125vw;
  }

  @media (max-width: 992px) {
    margin-left: 0;
  }
`

const Quotes = styled.img`
  position: absolute;
  top: -2rem;
  left: -5rem;
  display: none;
  width: 10.125rem;
  height: 7.3125rem;
  z-index: -1;

  @media (max-width: 992px) {
    display: block;
  }
`

const Title = styled.h2`
  ${styles.h2}

  @media (max-width: 992px) {
    margin-top: 2.5rem;
  }
`

const Description = styled.div`
  margin-top: 2.375rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.handwritten};
  font-weight: normal;
  font-size: 2.8125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    font-weight: normal;
    font-size: 2.1875rem;
    line-height: 2.5rem;
  }
`
