import 'leaflet/dist/leaflet.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  cta?: ButtonProps
  description?: string
  imageSmall?: ImageProps
  label?: string
  longitude?: string
  latitude?: string
  title?: string
  variant?: Variant
}

export const WideMap = memo(function WideMap({
  cta,
  description,
  imageSmall,
  label,
  longitude,
  latitude,
  title,
  variant = 'default',
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [108, 145],
        iconAnchor: [54, 105],
        html: `<img src="/map_marker.png" alt="Hotel Posta" width="108" height="145">`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {typeof window !== undefined && latitude && longitude ? (
          <Map
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={13}
            scrollWheelZoom={false}
            whenCreated={(mapInstance: any) => {
              map.current = mapInstance
              mapInstance.fitBounds(bounds)
              mapInstance.setZoom(16)
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            />
          </Map>
        ) : null}
        <Bottom>
          <Inner variant={variant}>
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
            {cta ? (
              <FadeInUp>
                <StyledButton {...cta} />
              </FadeInUp>
            ) : null}
          </Inner>
          {imageSmall ? <ImageSmall {...imageSmall} variant={variant} /> : null}
        </Bottom>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 46.25rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Map = styled(MapContainer)`
  position: relative;
  display: block;
  width: calc(100% + 8.9375rem);
  height: 66.666vh;
  max-height: 37.5rem;
  margin-top: 3.125rem;
  margin-right: -8.9375rem;

  @media (max-width: 1600px) {
    width: calc(100% + 8.9375vw);
    margin-right: -8.9375vw;
  }

  @media (max-width: 1199px) {
    width: calc(100% + 1.25rem);
    margin-right: -1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-top: 2.5rem;
    margin-left: -1.25rem;
  }

  @media (max-width: 550px) {
    max-height: 23.4375rem;
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.primaryLight};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -9999px;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.primaryDark};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.primaryLight};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }
`

const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 6.25rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const ImageSmall = styled(Image)<ContainerProps>`
  position: relative;
  display: block;
  width: 43%;
  max-width: 33.125rem;
  aspect-ratio: 530/418;
  order: 2;

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 2.5rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          order: 1;
        `
    }
  }}
`

const Inner = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 57%;
  max-width: 35rem;
  margin-top: 2.5rem;
  margin-right: 8.9375rem;
  order: 1;

  @media (max-width: 1440px) {
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    margin-right: 6.9375rem;
  }

  @media (max-width: 992px) {
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 550px) {
    display: block;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          margin-right: 0;
          margin-left: 8.9375rem;
          order: 2;

          @media (max-width: 1199px) {
            margin-right: 0;
            margin-left: 6.9375rem;
          }

          @media (max-width: 992px) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            order: 1;
          }
        `
    }
  }}
`

const Description = styled.div`
  ${styles.description}

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'inverted'
