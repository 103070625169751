import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  label?: string
  title?: string
}

export const InstagramSlider = memo(function InstagramSlider({
  images,
  label,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    drag: true,
    slides: {
      perView: 'auto',
      spacing: 30,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 'auto',
          spacing: 20,
        },
      },
      '(max-width: 550px)': {
        slides: {
          perView: 'auto',
          spacing: 15,
        },
      },
    },
  })

  return (
    <>
      <Container>
        <Wrapper>
          <Background />
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Wrapper>
      </Container>
      <SliderContainer>
        <FadeInUp>
          <Slider ref={sliderRef} className="keen-slider grab-cursor">
            {images.map((item, index) => (
              <Slide className="keen-slider__slide" key={index}>
                <StyledImage {...item.image} />
              </Slide>
            ))}
          </Slider>
        </FadeInUp>
      </SliderContainer>
    </>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;
  padding-top: 8.125rem;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
    padding-top: 4.375rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Background = styled.div`
  position: absolute;
  top: -8.125rem;
  height: 62.5rem;
  width: 100vw;
  right: calc(50% - 41.0625rem);
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  z-index: 0;

  @media (max-width: 1600px) {
    right: calc(50% - 41.0625vw);
  }

  @media (max-width: 1199px) {
    top: -4.375rem;
    right: 1.25rem;
    height: 40.75rem;
  }

  @media (max-width: 550px) {
    height: 33.125rem;
  }
`

const Label = styled.div`
  ${styles.label}
  position: relative;
  z-index: 1;
`

const Title = styled.h2`
  ${styles.h2}
  position: relative;
  max-width: 46.25rem;
  z-index: 1;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const SliderContainer = styled.div`
  margin-top: 3.75rem;
  margin-left: calc(50% - 41.0625rem);
  padding-bottom: 10.625rem;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    margin-left: 1.25rem;
    padding-bottom: 6.25rem;
  }
`

const Slider = styled.div`
  width: 100%;
`

const Slide = styled.div`
  max-width: 33.125rem;
  max-height: 33.125rem;
  min-width: 33.125rem;
  min-height: 33.125rem;

  @media (max-width: 1199px) {
    max-width: 21.875rem;
    max-height: 21.875rem;
    min-width: 21.875rem;
    min-height: 21.875rem;
  }

  @media (max-width: 550px) {
    max-width: 14.25rem;
    max-height: 14.25rem;
    min-width: 14.25rem;
    min-height: 14.25rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 33.125rem;
  aspect-ratio: 1;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 1.125rem;
    left: 1.125rem;
    width: 4.5%;
    height: 4.5%;
    min-width: 1.0625rem;
    min-height: 1.0625rem;
    background-image: url('/icon-instagram-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
  }
`
